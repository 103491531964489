import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { LinkInputProps } from "../types/interfaces";

const LinkInput: React.FC<LinkInputProps> = ({ projectLink }) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const timer = useRef<NodeJS.Timeout | null>(null);

  const copyToClipboard = () => {
    setIsCopied(true);

    navigator.clipboard.writeText(projectLink);
  };

  useEffect(() => {
    if (isCopied) {
      timer.current = setTimeout(() => setIsCopied(false), 2000);
    }

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
        timer.current = null;
      }
    };
  }, [isCopied]);

  return (
    <div className="link-input">
      <p className="link-input__description">Link</p>
      <div className="link-input__container">
        <input
          type="text"
          readOnly
          value={projectLink}
          className="link-input__input"
        />
        <FontAwesomeIcon
          onClick={copyToClipboard}
          icon={faCopy}
          className="link-input__icon"
        />
      </div>
      <span
        className={`link-input__message ${
          isCopied ? "link-input__message--show" : "link-input__message--hide"
        }`}
      >
        Copied to clipboard!
      </span>
    </div>
  );
};

export default LinkInput;
