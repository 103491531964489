import { useEffect, useState } from "react";
import { ProjectData, ProjectProps } from "../types/interfaces";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL as string;

const useProjects = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [projects, setProjects] = useState<ProjectProps[] | null>(null);

  const addProject = async (
    newProject: {
      title: string;
      description: string;
      projectUrl: string;
      gallery: File[];
    },
    next?: () => void
  ) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("title", newProject.title);
    formData.append("description", newProject.description);
    formData.append("projectUrl", newProject.projectUrl);

    newProject.gallery.forEach((image, index) => {
      formData.append("images", image);
    });

    try {
      const response = await axios.post(`${apiUrl}/api/upload`, formData);

      const project: ProjectProps = {
        imageUrl: response.data.project.imageUrl,
        title: response.data.project.title,
        id: response.data.project._id,
      };

      setProjects((prevState) => {
        if (prevState) {
          return [...prevState, project];
        } else {
          return [project];
        }
      });
    } catch (err) {
      setError(err instanceof Error ? err.message : "An error occurred");
    } finally {
      setLoading(false);
    }

    if (error.trim() === "" && next) {
      next();
    }
  };

  const deleteProject = async (projectId: string) => {
    setLoading(true);
    try {
      await axios.delete(`${apiUrl}/api/upload/${projectId}`);
      setProjects((prevState) => {
        if (prevState) {
          return prevState.filter((project) => project.id !== projectId);
        } else {
          return [];
        }
      });
    } catch (err) {
      setError(err instanceof Error ? err.message : "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const getPost = async (id: string): Promise<ProjectData | null> => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/api/projects/${id}`);
      return response.data;
    } catch (error) {
      setError(error instanceof Error ? error.message : "An error occurred");
      console.error(`Failed fetching project: ${error}`);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const updateProject = async (
    id: string,
    updatedProject: {
      title: string;
      description: string;
      projectUrl: string;
      gallery?: File[];
      imagesToRemove?: string[];
    },
    next?: () => void
  ) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("title", updatedProject.title);
    formData.append("description", updatedProject.description);
    formData.append("projectUrl", updatedProject.projectUrl);

    if (updatedProject.gallery) {
      updatedProject.gallery.forEach((item) => {
        formData.append("images", item);
      });
    }

    if (updatedProject.imagesToRemove) {
      updatedProject.imagesToRemove.forEach(item => {
        formData.append('imagesToRemove', item);
      })
    }

    console.log(typeof updatedProject.imagesToRemove);
    

    try {
      await axios.put(`${apiUrl}/api/upload/${id}`, formData);

      if (next) {
        next();
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : "An error occurred");
      console.log(err);
      
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchGalleryData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(apiUrl + "/api/projects");
        setProjects(response.data);
      } catch (error) {
        setError(error instanceof Error ? error.message : "An error occured");
      } finally {
        setLoading(false);
      }
    };

    fetchGalleryData();
  }, []);

  return {
    addProject,
    getPost,
    updateProject,
    loading,
    error,
    projects,
    deleteProject,
  };
};

export default useProjects;
