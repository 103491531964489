import React, { useCallback } from "react";
import { DragDropFileProps } from "../types/interfaces";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImage } from "@fortawesome/free-solid-svg-icons";

const DragDropFile: React.FC<DragDropFileProps> = ({ onFileUpload }) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      // Handle the upload files here
      onFileUpload(acceptedFiles);
    },
    [onFileUpload]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "image/*": [] },
  });

  return (
    <div {...getRootProps()} className="drag-drop__drop-zone">
        <FontAwesomeIcon className="drag-drop__icon" icon={faFileImage} />
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files here...</p>
      ) : (
        <p>Drag & Drop files here, or click to select files</p>
      )}
    </div>
  );
};

export default DragDropFile;
