import ProfileImage from "../assets/images/profile.jpeg";

import Carousel from "../components/Carousel";
import PostAuthorDetails from "../components/PostAuthorDetails";
import Layout from "../hoc/Layout";
import PostActions from "../components/PostActions";
import {
  faHeart as faSolidHeart,
  faCommentDots as faSolidCommentDots,
  faShareFromSquare as faSolidShareFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import {
  faHeart as faRegularHeart,
  faCommentDots as faRegularCommentDots,
  faShareFromSquare as faRegularShareFromSquare,
} from "@fortawesome/free-regular-svg-icons";
import React, { useCallback, useEffect, useState } from "react";
import { PostAction, ProjectData } from "../types/interfaces";
import Loader from "../components/Loader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Error from "../components/PageError";
import useProjects from "../hooks/useProjects";
import { Helmet } from "react-helmet-async";

const ProjectPage: React.FC = () => {
  const location = useLocation();
  const { id: postId } = useParams();
  const [icons, setIcons] = useState<PostAction[]>([
    {
      isFilled: false,
      number: Math.round(Math.random() * 100),
      onClick: () => {},
      regularIcon: faRegularHeart,
      solidIcon: faSolidHeart,
      id: "heart",
      additionalIconClass: "post__action-icon--red",
    },
    {
      isFilled: false,
      number: Math.round(Math.random() * 100),
      onClick: () => {},
      regularIcon: faRegularCommentDots,
      solidIcon: faSolidCommentDots,
      id: "comment",
    },
    {
      isFilled: false,
      number: Math.round(Math.random() * 100),
      onClick: () => {},
      regularIcon: faRegularShareFromSquare,
      solidIcon: faSolidShareFromSquare,
      id: "share",
    },
  ]);

  const { error, loading, getPost } = useProjects();
  const [post, setPost] = useState<ProjectData | null>(null);
  const navigate = useNavigate();

  const onIconClick = useCallback(
    (id: string) => {
      setIcons((prevState) => {
        const fieldIndex = icons.findIndex((icon) => icon.id === id);
        return prevState.map((icon, index) => {
          if (index !== fieldIndex) {
            return icon;
          }
          return {
            ...icon,
            isFilled: !icon.isFilled,
          };
        });
      });

      if (postId) {
        const projectLink = `${window.location.origin}${location.pathname}`;
        switch (id) {
          case "comment":
            return navigate("/contact", { state: { id: postId } });
          case "share":
            return navigate(`/share-post/${postId}`, {
              state: { projectLink: projectLink },
            });
        }
      }
    },
    [navigate, postId]
  );

  useEffect(() => {
    setIcons((prevState) =>
      prevState.map((icon) => ({ ...icon, onClick: onIconClick }))
    );
  }, [onIconClick]);

  useEffect(() => {
    const fetchPost = async () => {
      if (postId) {
        const fetchedPost = await getPost(postId);
        
        setPost(fetchedPost);
      }
    };

    fetchPost();
  }, []);

  return (
    <Layout headerIcon="close" headerTitle="Post">
      <Helmet>
        <title>@lior Karayev | {post?.title || 'Post'}</title>
      </Helmet>
      <div
        className={`project-page ${
          error.trim() !== "" ? "project-page__error" : ""
        }`}
      >
        {loading ? (
          <Loader />
        ) : error.trim() !== "" ? (
          <Error errorMessage={error} />
        ) : post ? (
          <>
            <Carousel items={post.gallery} />
            <h2 className="project-page__title">{post.title}</h2>
            <a href={`https://${post.projectUrl}`} target="_blank" className="project-page__url">
              #Visit Live Version of {post.title}
            </a>
            <PostAuthorDetails
              date={new Date(post.uploadDate).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
              description={post.description}
              profileImg={ProfileImage}
              username="Lior Karayev"
            />
            <PostActions actions={icons} />
          </>
        ) : (
          <Error errorMessage={"Oops... looks like something went wrong"} />
        )}
      </div>
    </Layout>
  );
};

export default ProjectPage;
