import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWifi } from "@fortawesome/free-solid-svg-icons";

const PageError: React.FC<{errorMessage: string}> = ({errorMessage}) => (
  <div className="page-error">
    <FontAwesomeIcon className="page-error-icon" icon={faWifi} />
    <h3 className="page-error-text">
      {errorMessage}
    </h3>
  </div>
);

export default PageError;
