import axios from "axios";
import { useState } from "react";

interface IContact {
  name: string;
  email: string;
  phone: string;
  message: string;
  date: string;
  id: string;
}

const apiUrl = process.env.REACT_APP_API_URL as string;

const useContact = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const getContacts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/api/contact`);
      return response.data;
    } catch (err) {
      setError(err instanceof Error ? err.message : "An error occurred");
      return null;
    } finally {
      setLoading(false);
    }
  };

  const getUnreadMessages = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/contact/unread-messages-amount`
      );
      return response.data;
    } catch (err) {
      setError(err instanceof Error ? err.message : "An error occurred");
      return {data: 0}
    }
  };

  const readMessage = async (id: string) => {
    try {
      const response = await axios.patch(
        `${apiUrl}/api/contact/read-message/${id}`
      );
      return response.data;
    } catch (err) {
      setError(err instanceof Error ? err.message : "An error occurred");
    }
  };

  const deleteMessage = async (id: string) => {
    try {
      const response = await axios.delete(`${apiUrl}/api/contact/${id}`);
      return response.data;
    } catch (err) {
      setError(err instanceof Error ? err.message : "An error occurred");
    }
  };

  const sendMessage = async (message: {
    fullName: string;
    email: string;
    phone: string;
    message: string;
  }, next?: () => void) => {
    setLoading(true);

    try {
      const response = await axios.post(`${apiUrl}/api/contact`, message);
      return response.data;
    } catch (err) {
      setError(err instanceof Error ? err.message : "An error occurred");
    } finally {
        setLoading(false);
    }

    if(!error && next) {
      next();
    }
  };

  return {
    loading,
    error,
    getContacts,
    getUnreadMessages,
    readMessage,
    deleteMessage,
    sendMessage
  };
};

export default useContact;
