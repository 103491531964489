import React, { useCallback, useEffect, useState } from "react";
import { InputProps } from "../types/interfaces";
import Layout from "../hoc/Layout";
import Form from "../components/Form";
import { useNavigate } from "react-router-dom";
import Backdrop from "../components/Backdrop";
import {
  faCheckCircle,
  faExclamationCircle,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "../components/Modal";
import Loader from "../components/Loader";
import useContact from "../hooks/useContact";
import Error from "../components/PageError";
import { Helmet } from "react-helmet-async";

const FormPage = () => {
  const navigate = useNavigate();

  const initialState: InputProps[] = [
    {
      name: "fullName",
      type: "text",
      value: "",
      isTouched: false,
      isValid: false,
      placeholder: "Full name",
      onChange: () => {},
      onBlur: () => {},
      onFocus: () => {},
    },
    {
      name: "email",
      type: "email",
      value: "",
      isTouched: false,
      isValid: false,
      placeholder: "Email",
      onChange: () => {},
      onBlur: () => {},
      onFocus: () => {},
    },
    {
      name: "phone",
      type: "number",
      value: "",
      isTouched: false,
      isValid: false,
      placeholder: "Phone",
      onChange: () => {},
      onBlur: () => {},
      onFocus: () => {},
    },
    {
      name: "message",
      type: "textarea",
      value: "",
      isTouched: false,
      isValid: false,
      placeholder: "Message",
      onChange: () => {},
      onBlur: () => {},
      onFocus: () => {},
    },
  ];

  const { loading, error, sendMessage } = useContact();

  const [inputs, setInputs] = useState<InputProps[]>(initialState);

  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [modal, setModal] = useState<{
    show: boolean;
    message: string;
    icon: IconDefinition;
  }>({
    show: false,
    message: "",
    icon: faExclamationCircle,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const validateField = (type: string, value: string) => {
    switch (type) {
      case "email":
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      default:
        return value.trim().length > 0;
    }
  };

  const validateForm = useCallback(() => {
    const invalidFieldIndex = inputs.findIndex((input) => !input.isValid);

    return invalidFieldIndex < 0;
  }, [inputs]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      const fieldName = name as keyof InputProps;
      const fieldIndex = inputs.findIndex((input) => input.name === fieldName);
      setInputs((prevState) => {
        const newInputs: InputProps[] = [...prevState];
        newInputs[fieldIndex] = {
          ...prevState[fieldIndex],
          value: value,
          isValid: validateField(prevState[fieldIndex].type, value),
        };

        return newInputs;
      });
    },
    []
  );

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name } = e.target;
      const fieldName = name as keyof InputProps;
      const fieldIndex = inputs.findIndex((input) => input.name === fieldName);

      setInputs((prevState) => {
        const value = prevState[fieldIndex].value;
        const newInputs: InputProps[] = [...prevState];
        newInputs[fieldIndex] = {
          ...prevState[fieldIndex],
          isValid: validateField(prevState[fieldIndex].type, value),
          isTouched: true,
        };

        return newInputs;
      });
    },
    []
  );

  const handleFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name } = e.target;
      const fieldName = name as keyof InputProps;
      const fieldIndex = inputs.findIndex((input) => input.name === fieldName);

      setInputs((prevState) => {
        const value = prevState[fieldIndex].value;
        const newInputs: InputProps[] = [...prevState];
        newInputs[fieldIndex] = {
          ...prevState[fieldIndex],
          isValid: validateField(prevState[fieldIndex].type, value),
          isTouched: true,
        };

        return newInputs;
      });
    },
    []
  );

  const resetForm = useCallback(() => {
    setInputs((prevState) =>
      prevState.map((input) => ({
        ...input,
        value: "",
        isValid: false,
        isTouched: false,
      }))
    );
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if(isFormValid) {
      let message = {
        fullName: inputs[0].value,
        email: inputs[1].value,
        phone: inputs[2].value,
        message: inputs[3].value,
      };

      const response = await sendMessage(message);
      if(error){
        setModal(prevState => ({...prevState, message: 'Couldn\'t send contact information. Please try again later', show: true}));
      } else {
        setModal(prevState => ({...prevState, message: 'Form submitted successfully!', show: true}));
      }
      
      resetForm();
    }
  };

  const closeModal = () => {
    setModal((prevState) => ({ ...prevState, show: false }));
    navigate(-1);
  };

  useEffect(() => {
    setInputs((prevState) =>
      prevState.map((input) => ({
        ...input,
        onChange: handleChange,
        onBlur: handleBlur,
        onFocus: handleFocus,
      }))
    );
  }, [handleChange, handleBlur, handleFocus]);

  useEffect(() => {
    if (isFormValid !== validateForm())
      setIsFormValid((prevState) => !prevState);
  }, [inputs, isFormValid, validateForm]);

  return (
    <Layout headerIcon="close" headerTitle="Contact Form">
      <Helmet>
        <title>@Lior Karayev | Contact Form</title>
      </Helmet>
      <div className="form-page">
        {loading ? (
          <Loader size="large" />
        ) : error ? (
          <Error errorMessage={error} />
        ) : (
          <Form
            inputs={inputs}
            isCancelBtn
            isValid={isFormValid}
            onSubmit={handleSubmit}
            onCancel={() => navigate("/")}
          />
        )}
      </div>

      <Backdrop show={modal.show} onClick={closeModal} />
      <Modal
        icon={modal.icon}
        message={modal.message}
        onClose={closeModal}
        show={modal.show}
      />
    </Layout>
  );
};

export default FormPage;
