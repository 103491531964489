import React from "react";
import { Helmet } from "react-helmet-async";

interface ISEO {
    title: string;
    description: string;
    type: string;
    name: string;
    keywords: string;
    image: string;
    url: string;
}

const SEO: React.FC<ISEO> = ({url, description, name, title, type, keywords, image}) => {
    return (
        <Helmet>
            {/* Standard meta tags */}
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="author" content={name} />
            <meta name="keywords" content={keywords} />
            <meta name="application-name" content={name} />

            {/* Facebook tags */}
            <meta property="og:type" content={type} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />

            {/* Twitter tags */}
            <meta property="twitter:creator" content={name} />
            <meta property="twitter:card" content={type} />
            <meta property="twitter:title" content={title} />
            <meta property="twitter:description" content={description} />
            <meta property="twitter:image" content={image} />
        </Helmet>
    )
};

export default SEO;