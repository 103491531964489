import { FormEvent, useEffect, useState } from "react";
import Layout from "../hoc/Layout";
import { UploadedFile } from "../types/interfaces";
import { useNavigate, useParams } from "react-router-dom";
import useProjects from "../hooks/useProjects";
import Loader from "../components/Loader";
import Error from "../components/PageError";
import ProjectDetailsTab from "../components/ProjectDetailsTab";
import ProjectImagesTab from "../components/ProjectImagesTab";

const EditProjectPage = () => {
  const { id } = useParams();
  const [currentTab, setCurrentTab] = useState<"Details" | "Images">("Details");
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [imagesToRemove, setImagesToRemove] = useState<string[]>([]);
  const [inputs, setInputs] = useState({
    title: "",
    url: "",
    description: "",
  });
  const navigate = useNavigate();

  const { error, getPost, updateProject, loading } = useProjects();

  const onFileUpload = (acceptedFiles: File[]) => {
    const newFiles = acceptedFiles.map((file) => ({
      file,
      name: file.name,
      preview: URL.createObjectURL(file),
      id: `${file.name}-${Date.now()}`,
    }));

    setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const setFiles = (items: UploadedFile[]) => {
    setUploadedFiles(items);
  };

  useEffect(() => {
    if (id) {
      const fetchPost = async () => {
        const post = await getPost(id);

        if (post) {
          setInputs({
            title: post.title,
            description: post.description,
            url: post.projectUrl,
          });

          const images = post.gallery.map((image) => {
            const name = image.split("/").pop() || "image";
            return {
              name,
              preview: image,
              id: `${name}-${Date.now()}`,
            };
          });

          setUploadedFiles(images);
        }
      };

      fetchPost();
    }
  }, []);

  const switchTab = () => {
    if (currentTab === "Details") {
      setCurrentTab("Images");
      return;
    } else {
      setCurrentTab("Details");
      return;
    }
  };

  const onCancel = () => {
    navigate("/admin");
  };

  const onInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    if (!id) {
      return;
    }
    setInputs((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleGalleryImageDelete = (item: UploadedFile) => {
    setImagesToRemove((prevState) => [...prevState, item.preview]);
    setUploadedFiles((prevState) =>
      prevState.filter((file) => file.name !== item.name)
    );
  };

  const onFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { description, title, url } = inputs;

    if (
      description.trim() === "" ||
      title.trim() === "" ||
      url.trim() === "" ||
      uploadedFiles.length === 0
    )
      return;

    const files = uploadedFiles
      .filter((file) => file.file && file.file instanceof File)
      .map((file) => file.file as File);
      

    if (id) {
      await updateProject(id, {
        title,
        description,
        projectUrl: url,
        gallery: files,
        imagesToRemove: imagesToRemove,
      }, () => navigate('/admin'));
    }
  };
  return (
    <Layout headerIcon="close" headerTitle="Edit Project">
      <div className="edit-project-page">
        {loading ? (
          <Loader />
        ) : error.trim() !== "" ? (
          <Error errorMessage={error} />
        ) : (
          <form onSubmit={onFormSubmit} className="edit-project">
            <div className="edit-project__tabs">
              <button
                type="button"
                className={`edit-project__tab ${
                  currentTab === "Details" ? "edit-project__tab--active" : ""
                }`}
                onClick={switchTab}
              >
                Details
              </button>
              <button
                type="button"
                className={`edit-project__tab ${
                  currentTab === "Images" ? "edit-project__tab--active" : ""
                }`}
                onClick={switchTab}
              >
                Images
              </button>
            </div>

            {currentTab === "Details" && (
              <ProjectDetailsTab
                inputs={inputs}
                onInputChange={onInputChange}
              />
            )}
            {currentTab === "Images" && (
              <ProjectImagesTab
                onFileUpload={onFileUpload}
                setFiles={setFiles}
                uploadedFiles={uploadedFiles}
                onImageDelete={handleGalleryImageDelete}
              />
            )}
            <div className="edit-project__actions">
              <button type="submit" className="button button--blue">
                Save
              </button>
              <button
                onClick={onCancel}
                type="button"
                className="button button--transparent"
              >
                Cancel
              </button>
            </div>
          </form>
        )}
      </div>
    </Layout>
  );
};

export default EditProjectPage;
