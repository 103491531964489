import React from "react";
import { ProfileProps } from "../types/interfaces";

const Profile: React.FC<ProfileProps> = ({ imageSrc, bio, username }) => {
  return (
    <div className="profile">
      <img src={imageSrc} alt="Profile" className="profile-image" />
      <h2>Hi {username}</h2>
      <p>{bio}</p>
    </div>
  );
};

export default Profile;
