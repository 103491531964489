import React, { useEffect, useState } from "react";
import { ProtectedRouteProps } from "../types/interfaces";
import { Navigate, Route, useNavigate } from "react-router-dom";
import { isLoggedIn } from "../utils/auth";
import Loader from "./Loader";
import PageError from "./PageError";
import Layout from "../hoc/Layout";

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [loggedIn, setLoggedIn] = useState<boolean>(false);
    const navigate = useNavigate();
    useEffect(() => {
        const validateToken = async () => {
            // await isLoggedIn() ? element : <Navigate to={'/'} />
            try {
                setLoading(true);
                const auth = await isLoggedIn();
                setLoggedIn(auth);

            } catch(err) {
                console.error("Unauthorized user: ", err);
                setLoggedIn(false);
            } finally {
                setLoading(false);
            }
        }

        validateToken();
    }, []);

    return (
        <div>
            {loading ? <Loader /> : loggedIn ? element : <Layout headerIcon="close" headerTitle="Protected Route" ><PageError errorMessage="Unauthorized" /></Layout>}
        </div>
    )
};

export default ProtectedRoute;