import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ShareButtonProps } from "../types/interfaces";
import { Link } from "react-router-dom";

const ShareButton: React.FC<ShareButtonProps> = ({
  description,
  icon,
  title,
  url,
}) => (
  <Link
    className="share__button"
    to={url}
    target="_blank"
    rel="noopener noreferrer"
  >
    <FontAwesomeIcon icon={icon} className="share__button-icon" />
    <p className="share__button-title">{title}</p>
    <p className="share__button-description">{description}</p>
  </Link>
);

export default ShareButton;
