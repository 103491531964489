import {
    faFacebook,
    faGithub,
    faLinkedin,
  } from "@fortawesome/free-brands-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { Link } from "react-router-dom";
  
  const Footer = () => {
    const linkedInUrl = process.env.REACT_APP_LINKEDIN_URL as string;
    const githubUrl = process.env.REACT_APP_GITHUB_URL as string;
    const facebookUrl = process.env.REACT_APP_FACEBOOK_URL as string;
  
    return (
      <footer className="footer">
        <div className="footer__icons">
          <a href={linkedInUrl} target="_blank" className="footer__icon-link">
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a href={githubUrl} target="_blank" className="footer__icon-link">
            <FontAwesomeIcon icon={faGithub} />
          </a>
          <a href={facebookUrl} target="_blank" className="footer__icon-link">
            <FontAwesomeIcon icon={faFacebook} />
          </a>
        </div>
        <p className="footer__copyright">&copy; {new Date().getFullYear()} Lior Karayev. All rights reserved.</p>
      </footer>
    );
  };
  
  export default Footer;
  