import ContactSubmissions from "../components/ContactSubmissions";
import Profile from "../components/Profile";
import Projects from "../components/Projects";

import ProfileImage from "../assets/images/profile.jpeg";
import Layout from "../hoc/Layout";
import ContactModal from "../components/ContactModal";
import Backdrop from "../components/Backdrop";
import { useEffect, useState } from "react";
import {
  ContactModal as ContactModalState,
  ContactSubmission,
  ProjectProps,
} from "../types/interfaces";
import useProjects from "../hooks/useProjects";
import useContact from "../hooks/useContact";
import { Helmet } from "react-helmet-async";

const AdminPage = () => {
  const [contactModal, setContactModal] = useState<ContactModalState>({
    fullName: "",
    date: "",
    email: "",
    _id: "",
    isRead: false,
    message: "",
    phone: "",
    onClose: () => {},
  });

  const [contactSubmissions, setContactSubmissions] = useState<
    ContactSubmission[]
  >([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [deletingProject, setDeletingProject] = useState<string | null>(null);
  const [unreadMessages, setUnreadMessages] = useState<number>(0);

  const {
    deleteProject,
    loading: projectsLoading,
    projects,
    error: projectsError,
  } = useProjects();
  const {
    error: contactsError,
    loading: contactsLoading,
    getContacts,
    getUnreadMessages,
    readMessage,
    deleteMessage
  } = useContact();

  const bio =
    "I'm a web developer with over 9 years of experience. I specialize in building and maintaining web applications.";

  const openModal = async (id: string, isRead: boolean) => {
    const info = contactSubmissions.filter(
      (submission) => submission._id === id
    )[0];
    info.isRead = true;
    setContactModal((prevState) => ({
      ...prevState,
      ...info,
    }));
    setIsModal(true);    

    if (!isRead) {
      await readMessage(id);
    }
  };

  const closeModal = () => {
    setContactModal((prevState) => ({ ...prevState, info: null }));
    setIsModal(false);
  };

  const onContactDelete = async (id: string) => {
    setContactSubmissions((prevState) =>
      prevState.filter((submission) => submission._id !== id)
  );

  const response = await deleteMessage(id);
  console.log(response);
  
  };

  const onProjectDelete = (id: string) => {
    setDeletingProject(id);
    setTimeout(() => deleteProject(id), 500);
  };

  useEffect(() => {
    setContactModal((prevState) => ({ ...prevState, onClose: closeModal }));
  }, []);

  useEffect(() => {
    const fetchContacts = async () => {
      const contacts = await getContacts();
      const unread = await getUnreadMessages();      

      setContactSubmissions(contacts);
      setUnreadMessages(unread.data);
    };

    fetchContacts();
  }, []);

  return (
    <Layout headerIcon="close" headerTitle="Admin">
      <Helmet>
        <title>@lior Karayev | Admin</title>
      </Helmet>
      <Profile bio={bio} imageSrc={ProfileImage} username="Lior Karayev" />
      <ContactSubmissions
        submissions={contactSubmissions}
        onOpen={(id: string, isRead: boolean) => openModal(id, isRead)}
        onRemove={onContactDelete}
        notRead={unreadMessages}
        isLoading={contactsLoading}
        error={contactsError}
      />
      <Projects
        onDelete={onProjectDelete}
        projects={projects}
        isLoading={projectsLoading}
        deletingProject={deletingProject}
        error={projectsError}
      />
      {isModal && <Backdrop onClick={closeModal} show />}
      {isModal && <ContactModal onClose={closeModal} info={contactModal} />}
    </Layout>
  );
};

export default AdminPage;
