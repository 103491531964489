import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { faEnvelope, faCommentSms } from "@fortawesome/free-solid-svg-icons";
import {
  faWhatsapp,
  faFacebook,
  faTwitter,
  faLinkedin,
  faTelegram,
  faRedditAlien,
  faPinterest,
} from "@fortawesome/free-brands-svg-icons";
import Layout from "../hoc/Layout";
import LinkInput from "../components/LinkInput";
import { ShareButtonProps } from "../types/interfaces";
import ShareButtons from "../components/ShareButtons";
import { Helmet } from "react-helmet-async";

const SharePost = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const projectLink: string = location.state?.projectLink || "";
  const [copiedToClipboard, setCopiedToClipboard] = useState<boolean>(false);

  const shareButtons: ShareButtonProps[] = [
    {
      description: "Personal message",
      icon: faWhatsapp,
      title: "WhatsApp",
      url: `https://wa.me/?text=${encodeURIComponent(projectLink)}`,
    },
    {
      description: "Personal message",
      icon: faFacebook,
      title: "Facebook",
      url: `https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2F${window.location.hostname}%2Fproject%2F${id}&amp;src=sdkpreparse`,
    },
    {
      description: "Personal message",
      icon: faTwitter,
      title: "Twitter",
      url: `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        projectLink
      )}&text=Check out this project!`,
    },
    {
      description: "Personal message",
      icon: faLinkedin,
      title: "LinkedIn",
      url: `https://www.linkedin.com/shareArticle?mini=true&?url=${encodeURIComponent(
        projectLink
      )}`,
    },
    {
      description: "Personal message",
      icon: faEnvelope,
      title: "Email",
      url: `mailto:?subject=Check out this project!&body=${encodeURIComponent(
        projectLink
      )}`,
    },
    {
      description: "Personal message",
      icon: faTelegram,
      title: "Telegram",
      url: `https://t.me/share/url?url=${encodeURIComponent(
        projectLink
      )}&text=Check out this project!`,
    },
    {
      description: "Personal message",
      icon: faRedditAlien,
      title: "Reddit",
      url: `https://reddit.com/submit?url=${encodeURIComponent(
        projectLink
      )}&title=Check out this project!`,
    },
    {
      description: "Personal message",
      icon: faPinterest,
      title: "Pinterest",
      url: `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(
        projectLink
      )}&description=Check out this project!`,
    },
    {
      description: "Personal message",
      icon: faCommentSms,
      title: "SMS",
      url: `sms:?&body=${encodeURIComponent(projectLink)}`,
    },
  ];

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (!projectLink || projectLink.trim().length === 0 || !id) {
      navigate("/");
    }

    if (copiedToClipboard) {
      timer = setTimeout(() => setCopiedToClipboard(false), 2000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [projectLink, navigate, copiedToClipboard, id]);

  return (
    <Layout headerIcon="arrow-back" headerTitle="Share">
      <Helmet>
        <title>@lior Karayev | Share Post</title>
      </Helmet>
      <div className="share">
        <LinkInput projectLink={projectLink} />
        <h2 className="share__title heading-secondary">Share via</h2>
        <ShareButtons buttons={shareButtons} />
      </div>
    </Layout>
  );
};

export default SharePost;
