import React from "react";
import { ProjectImagesGalleryProps } from "../types/interfaces";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableImage from "./DraggableImage";

const ProjectImagesGallery: React.FC<ProjectImagesGalleryProps> = ({
  uploadedFiles,
  setGallery,
  onDelete
}) => {
  const moveImage = (currentIndex: number, newIndex: number) => {
    const updatedFiles = Array.from(uploadedFiles);
    const [movedItem] = updatedFiles.splice(currentIndex, 1);
    updatedFiles.splice(newIndex, 0, movedItem);
    setGallery(updatedFiles);
  };

  return (
    <div className="uploaded-gallery">
      {uploadedFiles.map((file, index) => (
        <DraggableImage
          key={file.id}
          file={file}
          index={index}
          moveImage={moveImage}
          handleDelete={() => onDelete(file)}
        />
      ))}
    </div>
  );
};

export default ProjectImagesGallery;
