import React from "react";
import { ContactSubmission } from "../types/interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Loader from "./Loader";
import PageError from "./PageError";

const ContactSubmissions: React.FC<{
  onOpen: (id: string, isRead: boolean) => void;
  submissions: ContactSubmission[];
  onRemove: (id: string) => void;
  notRead: number;
  isLoading: boolean;
  error?: string | null;
}> = ({ onOpen, submissions, onRemove, notRead, isLoading, error }) => {
  return (
    <div className="contact-sbumissions">
      <h3>
        Contact Submissions{" "}
        <span className="new-message__amount">{notRead}</span>
      </h3>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <PageError errorMessage={error} />
      ) : (
        submissions.map((submission, index) => (
          <div className="submission" key={index}>
            <div>
              <strong>{submission.fullName}</strong>
              <p>{submission.message}</p>
            </div>
            <div className="submission__controls">
              <FontAwesomeIcon
                onClick={() => onRemove(submission._id)}
                icon={faTrash}
                className="submission__btn-delete"
              />
              <button
                onClick={() => onOpen(submission._id, submission.isRead)}
                className={`button button--primary ${
                  !submission.isRead ? "new-message__btn" : ""
                }`}
              >
                Open
              </button>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default ContactSubmissions;
