import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL as string;

export const isLoggedIn = async () => {
  const token = localStorage.getItem("userToken");
  
  try {
    const response = await axios.get(`${apiUrl}/api/auth/validate-token`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data.valid;
  } catch (err) {
    console.error("Error validating token: ", err);
    return false;
  }
};

export const login = async (username: string, password: string) => {
  try {
    const response = await axios.post(`${apiUrl}/api/auth/login`, {
      username,
      password,
    });

    const { token } = response.data;
    localStorage.setItem("userToken", token);
  } catch (err) {
    console.error("Failed login: ", err);
  }
};

export const logout = (): void => {
  localStorage.removeItem("userToken");
};
