import React, { FormEvent, useState } from "react";
import ProjectDetailsTab from "../components/ProjectDetailsTab";
import Layout from "../hoc/Layout";
import ProjectImagesTab from "../components/ProjectImagesTab";
import { UploadedFile } from "../types/interfaces";
import { useNavigate } from "react-router-dom";
import useProjects from "../hooks/useProjects";
import Loader from "../components/Loader";

const AddProject: React.FC = () => {
  const [currentTab, setCurrentTab] = useState<"details" | "images">("details");
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [inputs, setInputs] = useState({
    title: "",
    url: "",
    description: "",
  });

  const { addProject, error, loading } = useProjects();
  const navigate = useNavigate();

  const onFileUpload = (acceptedFiles: File[]) => {
    const newFiles = acceptedFiles.map((file) => ({
      file,
      name: file.name,
      preview: URL.createObjectURL(file),
      id: `${file.name}-${Date.now()}`,
    }));

    setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const setFiles = (items: UploadedFile[]) => {
    setUploadedFiles(items);
  };

  const switchTab = () => {
    if (currentTab === "details") {
      setCurrentTab("images");
      return;
    } else {
      setCurrentTab("details");
      return;
    }
  };

  const onCancel = () => {
    navigate("/admin");
  };

  const onInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const onFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { description, title, url } = inputs;

    if (
      description.trim() === "" ||
      title.trim() === "" ||
      url.trim() === "" ||
      uploadedFiles.length === 0
    )
      return;      

    const imageFiles = uploadedFiles
      .filter((fileObj) => fileObj.file !== undefined && fileObj.file instanceof File)
      .map(fileObj => fileObj.file as File);      

    if (imageFiles.length > 0) {
      addProject(
        { description, gallery: imageFiles, projectUrl: url, title },
        () => navigate("/admin")
      );
    }
  };

  const handleGalleryImageDelete = (file: UploadedFile) => {
    setUploadedFiles(prevState => prevState.filter(fileObj => fileObj.id !== file.id));
  }

  return (
    <Layout headerIcon="close" headerTitle="Add New Project">
      {loading ? (
        <Loader />
      ) : (
        <form onSubmit={onFormSubmit} className="add-project-page">
          <div className="add-project__tabs">
            <button
              onClick={switchTab}
              className={`add-project__tab ${
                currentTab === "details" ? "add-project__tab--active" : ""
              }`}
            >
              Details
            </button>
            <button
              onClick={switchTab}
              className={`add-project__tab ${
                currentTab === "images" ? "add-project__tab--active" : ""
              }`}
            >
              Images
            </button>
          </div>
          {currentTab === "details" && (
            <ProjectDetailsTab inputs={inputs} onInputChange={onInputChange} />
          )}
          {currentTab === "images" && (
            <ProjectImagesTab
              setFiles={setFiles}
              onFileUpload={onFileUpload}
              uploadedFiles={uploadedFiles}
              onImageDelete={handleGalleryImageDelete}
            />
          )}
          <div className="add-project__actions">
            <button type="submit" className="button button--blue">
              Save
            </button>
            <button
              type="button"
              onClick={onCancel}
              className="button button--transparent"
            >
              Cancel
            </button>
          </div>
        </form>
      )}
    </Layout>
  );
};

export default AddProject;
