import React from "react";
import Layout from "../hoc/Layout";

import ProfileImage from "../assets/images/profile.jpeg";
import CVFile from "../assets/files/CV.pdf";
import { Link, useNavigate } from "react-router-dom";
import useGalleryData from "../hooks/useGalleryData";
import Loader from "../components/Loader";
import PageError from "../components/PageError";
import useProjects from "../hooks/useProjects";
import { Helmet } from "react-helmet-async";
import SEO from "../components/SEO";

const ProfilePage: React.FC = () => {
  const navigate = useNavigate();
  // const { error, galleryItems, loading, reset } = useGalleryData();
  const { loading, error, projects } = useProjects();

  const downloadCV = () => {
    const link = document.createElement("a");
    link.href = CVFile;
    link.setAttribute("download", "CV.pdf");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Layout headerIcon="close" headerTitle="Profile">
      {/* <title>@Lior Karayev | Profile</title>
        <meta
          name="description"
          content="I'm Lior Karaev, a web developer from Israel with a focus on front-end development, particularly in ReactJS. With a background in NodeJS and ExpressJS."
        />
        <meta property="og:title" content="Lior Karayev" />
        <meta property="og:image" content="/logo.svg" />
        <meta name="keywords" content="web, developer, lior, karayev, full-stack, front-end, back-end, html, css, javascript, reactjs, ליאור, קרייב, פיתוח, אתרים" />
        <meta property="og:url" content="https://liorkaraev.com" /> */}
      <SEO
        description="I'm Lior Karaev, a web developer from Israel with a focus on front-end development, particularly in ReactJS. With a background in NodeJS and ExpressJS."
        image="https://liorkaraev.com/logo630.png"
        keywords="web, developer, lior, karayev, full-stack, front-end, back-end, html, css, javascript, reactjs, ליאור, קרייב, פיתוח, אתרים"
        name="Lior Karayev"
        title="@Lior Karayev | Profile"
        type="website"
        url="https://liorkaraev.com"
      />
      <div className="profile-page">
        <section className="profile-page__profile">
          <img
            src={ProfileImage}
            alt="Profile"
            className="profile-page__profile-image"
          />
          <h2 className="profile-page__profile-name">@Lior Karayev</h2>
          <p className="profile-page__profile-profession">#Web Developer</p>
        </section>

        <section className="profile-page__bio">
          <button
            onClick={() => navigate("/contact")}
            className="button button--secondary profile-page__bio-button"
          >
            Message
          </button>
          <p className="profile-page__bio-text">
            I'm Lior Karaev, a web developer from Israel with a focus on
            front-end development, particularly in ReactJS. With a background in
            NodeJS and ExpressJS, I'm expanding my skills to become a full-stack
            developer.
          </p>
          <button
            onClick={downloadCV}
            className="button button--secondary profile-page__bio-button"
          >
            Download CV
          </button>
        </section>

        <section className="profile-page__projects">
          <h3 className="profile-page__projects-title">Projects</h3>
          <section className="profile-page__projects-grid">
            {loading ? (
              <Loader size="medium" />
            ) : error ? (
              <PageError errorMessage={error} />
            ) : projects && projects.length > 0 ? (
              projects.map((item, index) => (
                <Link
                  key={`${item.title}_${index}`}
                  to={`/project/${item.id}`}
                  className="profile-page__projects-item"
                >
                  <img src={item.imageUrl} alt="Project" />
                  <p>{item.title}</p>
                </Link>
              ))
            ) : (
              <PageError errorMessage="Oops... looks like an error. Please try again later" />
            )}
          </section>
        </section>
      </div>
    </Layout>
  );
};

export default ProfilePage;
