import React from "react";
import { PostAuthor } from "../types/interfaces";

const PostAuthorDetails: React.FC<PostAuthor> = ({
  date,
  description,
  profileImg,
  username,
}) => {
  return (
    <div className="post-author">
      <div className="post-author__user">
        <img src={profileImg} alt="Profile" className="post-author__img" />
        <div className="post-author__user-details">
          <p className="post-author__username">{username}</p>
          <p className="post-author__date">Posted on {date}</p>
        </div>
      </div>
      <p className="post-author__description">{description}</p>
    </div>
  );
};

export default PostAuthorDetails;
