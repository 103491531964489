import React from "react";
import { Link } from "react-router-dom";
import { ProjectsProps } from "../types/interfaces";
import Loader from "./Loader";
import PageError from "./PageError";

const Projects: React.FC<ProjectsProps> = ({
  projects,
  onDelete,
  isLoading,
  deletingProject,
  error
}) => {
  return (
    <div className="projects">
      <h3>
        Projects{" "}
        <Link className="projects__btn-add" to={`/add-project`}>
          + Add New
        </Link>
      </h3>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <PageError errorMessage={error} />
      ) : (
        projects &&
        projects.map((project, index) => (
          <div
            key={index}
            className={`project ${
              project.id === deletingProject ? "project__deleting" : ""
            }`}
          >
            <div className="project-info">
              <div className="project-info__content">
                <h4>{project.title}</h4>
                <Link
                  className="project-info__btn-edit"
                  to={`/edit-project/${project.id}`}
                >
                  Edit
                </Link>
                <button
                  onClick={() => onDelete(project.id)}
                  className="project-info__btn-edit"
                >
                  Delete
                </button>
              </div>
              <Link to={`/project/${project.id}`} className="button button--primary">Open</Link>
            </div>
            <img src={project.imageUrl} alt="Project" />
          </div>
        ))
      )}
    </div>
  );
};

export default Projects;
