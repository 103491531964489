import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ContactModalProps } from "../types/interfaces";

const ContactModal: React.FC<ContactModalProps> = ({ info, onClose }) => (
  <div className="contact-modal">
    <FontAwesomeIcon
      onClick={onClose}
      className="contact-modal__btn"
      icon={faTimes}
    />
    <p className="contact-modal__name">
      <strong>Name:</strong> {info.fullName}
    </p>

    <p className="contact-modal__email">
      <strong>Email:</strong>{" "}
      <div className="contact-modal__email--content">{info.email}</div>
    </p>

    <p className="contact-modal__phone">
      <strong>Phone:</strong> {info.phone}
    </p>

    <p className="contact-modal__message">
      <strong>Message: </strong> {info.message}
    </p>
    <p className="contact-modal__date">
      <strong>Date:</strong>{" "}
      {new Date(info.date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      })}
    </p>
    <button onClick={onClose} className="button button--blue">
      Close
    </button>
  </div>
);

export default ContactModal;
