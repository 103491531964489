import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PostActionsProps } from "../types/interfaces";

const PostActions: React.FC<PostActionsProps> = ({ actions }) => {    
  return (
    <div className="post__actions">
      {actions.map((action, index) => (
        <button key={action.id} id={action.id} className="post__action" onClick={() => action.onClick(action.id)}>
          <div
            className={`post__action-icon-container ${
              !action.isFilled
                ? "post__action-icon-container--scale-down"
                : "post__action-icon-container--scale-up"
            }`}
          >
            <FontAwesomeIcon
              className={`post__action-icon ${action.additionalIconClass} ${
                !action.isFilled ? "post__action-icon--hide" : ""
              }`}
              icon={action.solidIcon}
              onClick={() => action.onClick(action.id)}
            />{" "}
          </div>
          <div
            className={`post__action-icon-container ${
              action.isFilled
                ? "post__action-icon-container--scale-down"
                : "post__action-icon-container--scale-up"
            }`}
          >
            <FontAwesomeIcon
              className={`post__action-icon  ${
                action.isFilled ? "post__action-icon--hide" : ""
              }`}
              icon={action.regularIcon}
            />{" "}
          </div>
          {action.isFilled ? action.number + 1 : action.number}
        </button>
      ))}
    </div>
  );
};

export default PostActions;
