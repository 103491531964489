import React from "react";
import { FormProps } from "../types/interfaces";
import Input from "./Input";

const Form: React.FC<FormProps> = ({
  inputs,
  isCancelBtn,
  onCancel,
  onSubmit,
  isValid,
}) => {
  return (
    <form className="form" onSubmit={onSubmit}>
      <div className="form__inputs">
        {inputs.map((input, index) => (
          <Input
            key={index}
            name={input.name}
            type={input.type}
            placeholder={input.placeholder}
            value={input.value}
            onChange={input.onChange}
            onBlur={input.onBlur}
            onFocus={input.onFocus}
            isTouched={input.isTouched}
            isValid={input.isValid}
          />
        ))}
      </div>
      <div className="form__buttons">
        <button
          type="submit"
          disabled={!isValid}
          className={`button button--blue ${!isValid ? 'button--disabled' : ''}`}
        >
          Send
        </button>
        {isCancelBtn && (
          <button onClick={onCancel} type="button" className="button button--transparent">
            Cancel
          </button>
        )}
      </div>
    </form>
  );
};

export default Form;
