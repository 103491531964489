import React from "react";
import { ModalProps } from "../types/interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const Modal: React.FC<ModalProps> = ({ icon, message, onClose, show }) => {
    if(!show) return null;

    return (
        <div className="modal">
            <div className="modal__content">
                <button className="modal__close" onClick={onClose}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
                <FontAwesomeIcon icon={icon} className="modal__icon" />
                <p className="modal__message">{message}</p>
                <button className="button button--blue" onClick={onClose}>
                    OK
                </button>
            </div>
        </div>
    );
};

export default Modal;