import React from "react";

const ProjectDetailsTab: React.FC<{
  inputs: { title: string; url: string; description: string };
  onInputChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}> = ({ inputs, onInputChange }) => (
  <div className="project-details">
    <input
      placeholder="Title"
      value={inputs.title}
      onChange={onInputChange}
      type="text"
      id="title"
      className="project-details__input"
    />
    <input
      placeholder="Project URL"
      value={inputs.url}
      onChange={onInputChange}
      type="text"
      id="url"
      className="project-details__input"
    />
    <textarea
      placeholder="Project description"
      value={inputs.description}
      onChange={onInputChange}
      id="description"
      className="project-details__input"
      rows={3}
    ></textarea>
  </div>
);

export default ProjectDetailsTab;
