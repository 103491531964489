import React from "react";
import { InputProps } from "../types/interfaces";

const Input: React.FC<InputProps> = ({
  type,
  value,
  name,
  placeholder,
  isValid,
  isTouched,
  onChange,
  onBlur,
  onFocus,
}) => {
  return type === "textarea" ? (
    <textarea
      className={`input ${!isValid && isTouched ? 'input--invalid' : ''}`}
      name={name}
      rows={3}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
    ></textarea>
  ) : (
    <input
      className={`input ${!isValid && isTouched ? 'input--invalid' : ''}`}
      type={type}
      name={name}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );
};

export default Input;
