import React from "react";
import { ShareButtonsProps } from "../types/interfaces";
import ShareButton from "./ShareButton";

const ShareButtons: React.FC<ShareButtonsProps> = ({ buttons }) => {
  return (
    <div className="share__buttons">
      {buttons.map((button, index) => (
        <ShareButton
          key={index}
          description={button.description}
          icon={button.icon}
          title={button.title}
          url={button.url}
        />
      ))}
    </div>
  );
};

export default ShareButtons;
