import React, { createContext, useContext, useState, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';

interface PreviousURLContextType {
    previousURL: string | null;
};

const PreviousURLContext = createContext<PreviousURLContextType>({
    previousURL: null
});

export const PreviousURLProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
    const location = useLocation();
    const [previousURL, setPreviousURL] = useState<string | null>(null);
    const currentURL = useRef<string | null>(null);

    useEffect(() => {
        // Update previousURL state whenever location changes
        if(currentURL.current !== null && previousURL !== location.pathname){
            setPreviousURL(location.pathname);
        }

        currentURL.current = location.pathname;
    }, [location.pathname, previousURL]);

    return (
        <PreviousURLContext.Provider value={{previousURL}}>
            {children}
        </PreviousURLContext.Provider>
    )
}

// Custom hook to use the PreviousURLContext
export const usePreviousURL = () => useContext(PreviousURLContext);