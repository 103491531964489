import React from "react";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import { LayoutProps } from "../types/interfaces";

const Layout: React.FC<LayoutProps> = ({
  children,
  headerIcon,
  headerTitle,
}) => (
  <div className="layout">
    <div className="container">
      <Header icon={headerIcon} title={headerTitle} />
      <main className="layout__main">{children}</main>
      <Footer />
    </div>
  </div>
);

export default Layout;
