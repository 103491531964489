import Error from "../components/PageError";
import Footer from "../layout/Footer";
import Header from "../layout/Header";

const NotFoundPage = () => {
  return (
    <div className="container">
      <Header icon="close" title="Not Found" />
      <main>
        <Error errorMessage="Oops. Looks like the page you're looking for doesn't exist." />
      </main>
      <Footer />
    </div>
  );
};

export default NotFoundPage;
