import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { CarouselProps } from "../types/interfaces";
import { SwipeEventData, useSwipeable } from "react-swipeable";

const Carousel: React.FC<CarouselProps> = ({ items }) => {
  const [itemWidth, setItemWidth] = useState<number>(0);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [showActions, setShowActions] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const itemRef = useRef<HTMLDivElement>(null);

  const isSwipingRef = useRef<boolean>(false);
  const startScrollingLeftRef = useRef<number>(0);

  // Measure the width of a single item
  useEffect(() => {
    const measureItemWidth = () => {
      if (itemRef.current) {
        const width = itemRef.current.offsetWidth;
        setItemWidth(width);
      }
    };

    measureItemWidth();

    // Update itemWidth on window resize for responsiveness
    window.addEventListener("resize", measureItemWidth);
    return () => window.removeEventListener("resize", measureItemWidth);
  }, []);

  // Scroll to a specific index and update currentIndex
  const scrollToIndex = useCallback(
    (index: number) => {
      if (containerRef.current && itemWidth > 0) {
        const clampedIndex = Math.max(0, Math.min(index, items.length - 1));
        const scrollPosition = clampedIndex * itemWidth;

        containerRef.current.scrollTo({
          left: scrollPosition,
          behavior: "smooth",
        });

        setCurrentIndex(clampedIndex);
      }
    },
    [itemWidth, items.length]
  );

  const nextSlide = () => {
    if (currentIndex + 1 <= items.length) {
      scrollToIndex(currentIndex + 1);
    }
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      scrollToIndex(currentIndex - 1);
    }
  };

  const mouseEnterHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    setShowActions(true);
  }

  const mouseLeaveHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    setShowActions(false);
  }

  // Swipe handlers using useSwipeable
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      isSwipingRef.current = false;
      if (currentIndex < items.length - 1) {
        scrollToIndex(currentIndex + 1);
      } else {
        console.log("Reached the end of the carousel.");
      }
    },
    onSwipedRight: () => {
      isSwipingRef.current = false;
      if (currentIndex > 0) {
        scrollToIndex(currentIndex - 1);
      } else {
        console.log("At the beginning of the carousel.");
      }
    },
    onSwiping: (eventData: SwipeEventData) => {
      if(!containerRef.current) return;

      const { deltaX } = eventData;

      if(!isSwipingRef.current){
        isSwipingRef.current = true;
        startScrollingLeftRef.current = containerRef.current.scrollLeft;
      }

      let newScrollLeft = startScrollingLeftRef.current - deltaX;

      let maxScrollLeft = (currentIndex + 1) * itemWidth;
      if(maxScrollLeft > (items.length - 1) * itemWidth){
        maxScrollLeft = (items.length - 1) * itemWidth;
      }
      let minScrollLeft = 0;
      if((currentIndex - 1) * itemWidth > 0){
        minScrollLeft = (currentIndex - 1) * itemWidth;
      }
      console.log(maxScrollLeft);
      
      newScrollLeft = Math.max(minScrollLeft, Math.min(newScrollLeft, maxScrollLeft));

      containerRef.current.scrollLeft = newScrollLeft;

      if(!showActions){
        setShowActions(true);
      }
    },
    onSwiped: (eventData: SwipeEventData) => {
      setTimeout(() => setShowActions(false), 2000)
    },
    delta: 50, // Minimum distance(px) before a swipe is detected
    trackTouch: true,
    trackMouse: false,
    // preventDefaultTouchmoveEvent: true, // Omitted due to TypeScript error
  });

  return (
    <div
      className={`carousel ${
        showActions ? "carousel--show-actions" : "carousel--hide-actions"
      }`}
      {...handlers}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
    >
      <p className="carousel__indicator">
        {currentIndex + 1} / {items.length}
      </p>
      <div className="carousel__items" ref={containerRef}>
        {items.map((item, index) => (
          <div
            key={index}
            className="carousel__item"
            ref={index === 0 ? itemRef : null} // Assign ref only to the first item
          >
            <img
              src={item}
              alt={`Carousel item ${index + 1}`}
              className="carousel__img"
            />
          </div>
        ))}
      </div>
      <button
        onClick={nextSlide}
        className="carousel__button carousel__button--next"
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
      <button
        onClick={prevSlide}
        className="carousel__button carousel__button--prev"
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
    </div>
  );
};

export default Carousel;
