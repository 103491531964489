import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./sass/main.scss";
import ProfilePage from "./pages/ProfilePage";
import ProjectPage from "./pages/Project";
import FormPage from "./pages/FormPage";
import SharePost from "./pages/SharePost";
import { PreviousURLProvider } from "./hooks/usePreviousUrl";
import NotFoundPage from "./pages/NotFoundPage";
import AdminPage from "./pages/AdminPage";
import LoginPage from "./pages/LoginPage";
import AddProject from "./pages/AddProject";
import EditProjectPage from "./pages/EditProjectPage";
import ProtectedRoute from "./components/ProtectedRoute";
import { HelmetProvider } from "react-helmet-async";

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <PreviousURLProvider>
        <HelmetProvider>
          <Routes>
            <Route path="/" element={<ProfilePage />} />
            <Route path="/project/:id" element={<ProjectPage />} />
            <Route path="/contact" element={<FormPage />} />
            <Route path="/share-post/:id" element={<SharePost />} />
            <Route path="/admin-login" element={<LoginPage />} />
            <Route
              path="/admin"
              element={<ProtectedRoute element={<AdminPage />} />}
            />
            <Route
              path="/add-project"
              element={<ProtectedRoute element={<AddProject />} />}
            />
            <Route
              path="/edit-project/:id"
              element={<ProtectedRoute element={<EditProjectPage />} />}
            />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </HelmetProvider>
      </PreviousURLProvider>
    </BrowserRouter>
  );
};

export default App;
