import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong, faXmark } from '@fortawesome/free-solid-svg-icons';
import { HeaderProps } from "../types/interfaces";

const Header: React.FC<HeaderProps> = ({icon, title}) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    if(window.history.length > 1 && window.location.pathname !== '/') {
      navigate(-1);
    } else {
      navigate('/');
    }
  }
    return(
        <header className="header">
          <button onClick={handleGoBack} className="header__btn">
            <FontAwesomeIcon icon={icon === 'arrow-back' ? faArrowLeftLong : faXmark } />
          </button>
          <h1 className="header__title">{title}</h1>
        </header>
    )
}

export default Header;