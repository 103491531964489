import React from "react";
import { useDrag, useDrop } from "react-dnd";
import { UploadedFile } from "../types/interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsAlt, faTrash } from "@fortawesome/free-solid-svg-icons";

interface DraggedItem {
  index: number;
}

const DraggableImage: React.FC<{
  file: UploadedFile;
  index: number;
  moveImage: (currentIndex: number, newIndex: number) => void;
  handleDelete: (id: string) => void;
}> = ({ moveImage, file, handleDelete, index }) => {
  const [{ isDragging }, dragRef] = useDrag({
    type: "IMAGE",
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const [, dropRef] = useDrop({
    accept: "IMAGE",
    hover: (draggedItem: DraggedItem, monitor) => {
      if(!monitor) return;
      if (draggedItem.index !== index) {
        moveImage(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return <div ref={dropRef} className={`uploaded-gallery__item ${isDragging ? 'dragging' : ''}`}>
    <img ref={dragRef} src={file.preview} alt={file.name} className="uploaded-gallery__img" />
    <div className="uploaded-gallery__controls">
        <span className="uploaded-gallery__control">
            <FontAwesomeIcon icon={faArrowsAlt} />
        </span>
        <span onClick={() => handleDelete(file.name)} className="uploaded-gallery__control">
            <FontAwesomeIcon icon={faTrash} />
        </span>
    </div>
  </div>;
};

export default DraggableImage;
