import React, { useState } from "react";
import Layout from "../hoc/Layout";
import { UserState } from "../types/interfaces";
import { login } from "../utils/auth";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const [user, setUser] = useState<UserState>({username: '', password: ''});
  const navigate = useNavigate();

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {id, value} = e.target;

    setUser(prevState => ({
      ...prevState,
      [id]: value
    }))
  };

  const onLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const {username, password} = user;

    if(username.trim() === '' || password.trim() === '') return;

    login(username, password);
    navigate('/admin');
  };
  return (
    <Layout headerIcon="close" headerTitle="Admin Login">
      <div className="login-page">
        <form onSubmit={onLogin} className="login-form">
          <input
            placeholder="Username"
            onChange={onInputChange}
            value={user.username}
            type="text"
            id="username"
            className="login-form__input"
          />
          <input
            placeholder="Password"
            onChange={onInputChange}
            value={user.password}
            type="password"
            id="password"
            className="login-form__input"
          />
          <input type="submit" value="Login" className="login-form__btn" />
        </form>
      </div>
    </Layout>
  );
};

export default LoginPage;
