import React from "react";
import DragDropFile from "./DragDropFile";
import { ProjectImagesTabProps, UploadedFile } from "../types/interfaces";
import ProjectImagesGallery from "./ProjectImagesGallery";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const ProjectImagesTab: React.FC<ProjectImagesTabProps> = ({
  onFileUpload,
  uploadedFiles,
  setFiles,
  onImageDelete
}) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <div className="project-images">
        <DragDropFile onFileUpload={onFileUpload} />
        {uploadedFiles.length > 0 && (
          <ProjectImagesGallery
            uploadedFiles={uploadedFiles}
            setGallery={setFiles}
            onDelete={onImageDelete}
          />
        )}
      </div>
    </DndProvider>
  );
};

export default ProjectImagesTab;
